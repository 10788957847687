import { NgModule } from '@angular/core';
import { MinervaButtonComponent } from '@app/payrolls/payroll-chat-bot/minerva-button/minerva-button.component';
import { PayrollChatBotComponent } from '@app/payrolls/payroll-chat-bot/payroll-chat-bot.component';
import { SharedModule } from '@app/shared.module';
import { ChatBotDataService } from '@design/chat-bot/chat-bot-data.service';
import { PayrollsChatbotService } from './payrolls-chat-bot.service';
import { ChatConversationService } from '@design/chat-bot/chat-conversation.service';
import { ChatBotStateService } from '@design/chat-bot/state/chat-bot.state-service';
import { PayrollChatBotConversationService } from './payroll-chat-bot.conversation.service';

@NgModule({
  declarations: [MinervaButtonComponent, PayrollChatBotComponent],
  imports: [SharedModule],
  exports: [MinervaButtonComponent],
  providers: [
    ChatBotStateService,
    { provide: ChatBotDataService, useClass: PayrollsChatbotService },
    { provide: ChatConversationService, useClass: PayrollChatBotConversationService }
  ]
})
export class PayrollChatBotModule {}
