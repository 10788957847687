import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OrganisationPayrollsDataProvider, OrganisationPayrollsStateService } from '@shared-payrolls';
import { Router } from '@angular/router';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { map, take } from 'rxjs/operators';
import { TabNavigationDataset } from '@app/app-layout/header/mobile-tab-navigation/mobile-tab-entity';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-mobile-tab-navigation',
  templateUrl: './mobile-tab-navigation.component.html',
  styleUrls: ['./mobile-tab-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrganisationPayrollsStateService, OrganisationPayrollsDataProvider],
  standalone: false
})
export class MobileTabNavigationComponent {
  tabNameList = ['None', 'Bureau', 'Payroll', 'EmployeeHub', 'AdminHub', 'Hr'];
  pickerMenuOpen = false;
  tabsData$ = this.activeSecurity.activeAreas$.pipe(
    map((navData) => {
      let tabsData = [];

      if (navData.bureauTab?.showTab) {
        tabsData.push({
          id: 1,
          name: navData.bureauTab.label,
          imageSrc: '/assets/img/BureauActive.svg',
          backgroundColor: 'bureau-blue',
          altText: 'Bureau icon'
        });
      }
      if (navData.payrollTab?.showTab) {
        tabsData.push({
          id: 2,
          name: navData.payrollTab.label,
          imageSrc: '/assets/img/PayrollOutlineActive.svg',
          backgroundColor: 'primary',
          altText: 'Payroll icon'
        });
      }
      if (navData.employeeHubTab?.showTab) {
        tabsData.push({
          id: 3,
          name: navData.employeeHubTab.label,
          imageSrc: '/assets/img/EmployeeHubActive.svg',
          backgroundColor: 'employee-hub-blue',
          altText: 'Employee hub icon'
        });
      }
      if (navData.adminHubTab?.showTab) {
        tabsData.push({
          id: 4,
          name: navData.adminHubTab.label,
          imageSrc: '/assets/img/AdminHubActive.svg',
          backgroundColor: 'system-purple',
          altText: 'Admin hub icon'
        });
      }
      if (navData.hrTab?.showTab) {
        tabsData.push({
          id: 5,
          name: navData.hrTab.label,
          imageSrc: '/assets/img/EmployeeHubActive.svg',
          backgroundColor: 'employee-hub-blue',
          altText: 'Hr hub icon'
        });
      }
      return tabsData;
    })
  );
  tabsDataSet$ = this.tabsData$.pipe(
    map((tabsData) => {
      return new TabNavigationDataset(tabsData);
    })
  );

  useSelect$ = this.tabsDataSet$.pipe(map((tabs) => tabs.items.length > 1));
  selectedItemId$ = this.activeCintraProductService.activeProduct$.pipe(
    map((activeProduct) => {
      return this.tabNameList.indexOf(activeProduct.toString());
    })
  );

  currentArea$ = combineLatest([this.selectedItemId$, this.tabsData$]).pipe(
    map(([selectedItemId, tabsData]) => {
      return tabsData.find((t) => t.id == selectedItemId);
    })
  );

  constructor(
    private router: Router,
    public activeSecurity: ActiveSecurityContextStateService,
    public activeCintraProductService: ActiveCintraProductService
  ) {}

  navigate(item) {
    combineLatest([this.currentArea$, this.activeSecurity.activeAreas$])
      .pipe(take(1))
      .subscribe(([area, tabData]) => {
        if (area.id != item.id) {
          switch (item.id) {
            case 1:
              this.router.navigateByUrl(tabData.bureauTab.path).then();
              break;
            case 2:
              this.router.navigateByUrl(tabData.payrollTab.path).then();
              break;
            case 3:
              this.router.navigateByUrl(tabData.employeeHubTab.path).then();
              break;
            case 4:
              this.router.navigateByUrl(tabData.adminHubTab.path).then();
              break;
            case 5:
              window.location.href = tabData.hrTab.path;
              break;
          }
        }
      });

    this.pickerMenuOpen = false;
  }

  togglePicker() {
    this.useSelect$.subscribe((useSelect) => {
      if (useSelect) this.pickerMenuOpen = !this.pickerMenuOpen;
      else this.pickerMenuOpen = false;
    });
  }
}
