<div>
  <div
    tid="mobile-tab-selector"
    class="tab-picker"
    (click)="togglePicker()"
    *ngIf="currentArea$ | async as currentArea"
    [ngClass]="currentArea.backgroundColor"
  >
    <img class="option-image" width="20" height="20" src="{{ currentArea.imageSrc }}" alt="{{ currentArea.altTag }}" />
    <span>{{ currentArea.name }}</span>
    <mat-icon *ngIf="useSelect$ | async" [ngClass]="{ 'trigger-icon': true, expanded: pickerMenuOpen }">arrow_drop_down</mat-icon>
  </div>
  <ul *ngIf="tabsDataSet$ | async as tabsDataset" tid="tab-picker-menu" [ngClass]="{ open: pickerMenuOpen, 'tab-picker-menu': true }">
    <li *ngFor="let item of tabsDataset.items; let index = index" (click)="navigate(item)" [ngClass]="item.backgroundColor">
      <img class="option-image" width="20" height="20" src="{{ item.imageSrc }}" alt="{{ item.altText }}" />
      <span>{{ tabsDataset.getLabel(item) }}</span>
      <div *ngIf="(selectedItemId$ | async) === item.id" class="active-icon"></div>
    </li>
  </ul>
  <div *ngIf="pickerMenuOpen" (click)="togglePicker()" class="tab-navigator-backdrop"></div>
</div>
