import { Injectable, Inject } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ChatConversationService } from '@design/chat-bot/chat-conversation.service';
import { ChatBotDataService } from '@design/chat-bot/chat-bot-data.service';
import { ChatBotStateService } from '@design/chat-bot/state/chat-bot.state-service';
import { FeedbackEmotion } from '@design/chat-bot/chat-models';

@Injectable()
export class PayrollChatBotConversationService extends ChatConversationService {
  constructor(
    @Inject(ChatBotDataService) protected dataService: ChatBotDataService,
    @Inject(ChatBotStateService) protected chatBotStateService: ChatBotStateService
  ) {
    super(chatBotStateService);
  }

  fetchAssistantResponse(userMessage: string) {
    this.inFlight$.next(true);

    const chatBot = this.chatBotStateService.getChatBotModel();

    this.dataService
      .sendMessage$(userMessage, chatBot.sessionId)
      .pipe(finalize(() => this.inFlight$.next(false)))
      .subscribe((response) => {
        this.chatBotStateService.updateSessionId(response.sessionId);
        this.chatBotStateService.addChatMessage(response);
        this.acceptUserFeedback();

        if (response.reportId) {
          this.fetchReportData(response.sessionId, response.reportId);
        }
      });
  }

  sendUserFeedback(emotion: FeedbackEmotion, content?: string) {
    this.inFlight$.next(true);
    const chatBot = this.chatBotStateService.getChatBotModel();

    this.dataService
      .sendFeedback$(emotion, chatBot.sessionId, content)
      .pipe(finalize(() => this.inFlight$.next(false)))
      .subscribe({
        next: () => {
          this.acceptUserFeedback$.next(false);
          this.feedbackSent$.next(true);
        },
        error: (error) => console.error('Error sending feedback:', error)
      });
  }

  private fetchReportData(sessionId: string, reportId: string) {
    this.dataService.fetchInlineReport$(sessionId, reportId).subscribe((inlineReport) => {
      this.chatBotStateService.addReport(reportId, inlineReport);
    });
  }
}
