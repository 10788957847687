import { ChatBotDataService } from '@design/chat-bot/chat-bot-data.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ChatMessage, ChatResponse, ChatSessionReportResponse, FeedbackEmotion, InlineReport } from '@design/chat-bot/chat-models';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FileDownloadService } from '@utils/file-download.service';
import { DateTime } from 'luxon';

@Injectable()
export class PayrollsChatbotService extends ChatBotDataService {
  constructor(
    protected override http: HttpClient,
    private fileDownload: FileDownloadService
  ) {
    super(http);
  }

  sendMessage$(message: string, sessionId?: string): Observable<ChatMessage> {
    return this.http
      .post<ChatResponse>(`${environment.cindaApiRoot}question`, {
        sessionId,
        question: message
      })
      .pipe(
        map(
          (r) =>
            <ChatMessage>{
              sessionId: r.sessionId,
              content: r.message,
              role: 'assistant',
              reportId: r.report?.id,
              reportName: r.report?.name
            }
        )
      );
  }

  fetchInlineReport$(sessionId: string, reportId: string): Observable<InlineReport> {
    return this.http.get<ChatSessionReportResponse>(`${environment.cindaApiRoot}chat-session/${sessionId}/report/${reportId}`).pipe(
      map((r) => {
        return <InlineReport>{
          sessionId: r.sessionId,
          reportId: r.reportId,
          reportName: r.reportName,
          rows: r.content.rows.map((row) => row),
          columns: r.content.columns
        };
      })
    );
  }

  downloadReport$(reportData: InlineReport): Observable<any> {
    const url = `${environment.cindaApiRoot}chat-session/${reportData.sessionId}/report/${reportData.reportId}/download`;
    const fileName = `${reportData.reportName} ${DateTime.now().toFormat('yyyyMMdd-HHmmss')}.xlsx`;

    return this.fileDownload.downloadFile$(url, fileName);
  }

  transformAgGrid$(userPrompt: string, gridConfiguration: string, sessionId?: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
